body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Rockwell-MT,Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Rockwell-MT';
  src: local('Rockwell-MT'), url(./fonts/Rockwell-MT.ttf) format('truetype');
}


.eligibilityCheck .MuiInput-formControl {
  margin-top: 52px;
}
.eligibilityCheck .charitableWorkArea  .MuiInput-formControl {
  margin-top: 32px;
}
.eligibilityCheck .field {
  margin-top: 32px;
}

.MuiStepIcon-root.MuiStepIcon-completed,
.MuiStepIcon-root.MuiStepIcon-active {
  color:#8aaf45 !important;
}

.actions {
  float:right !important;
}

.selectRadio .MuiOutlinedInput-input {
    padding:7px;
}

.selectRadio .MuiOutlinedInput-root {
  min-height: 56px;
}

.MuiAccordionDetails-root {
  display: block !important;
}
