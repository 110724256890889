.Dropzone {
    min-height: 200px;
    width: 100%;
    background-color: #ececec;
    border: 2px dashed #ccc;
    border-radius: 2px;
    display: flex;
  
    flex-direction: column;
    font-size: 16px;
  }
  
 

  .Highlight  {
    background-color:#00000061;
    border: 2px solid #8aaf45;
  }

  .Highlight .MuiSvgIcon-colorSecondary {
    color:#ce0d28;
  }

  

  .icon {
   margin:auto;
   text-align: center;
  }

  .icon p {
    font-size: .8em;
    color:#ce0d28;
   }

   .Highlight p {
    color:#fff;
  }
  
  .FileInput {
    display: none;
  }